import { ColumnTypeIdentifier } from '../../../grid/reduxStore/table.types';

export const roundDecimalValue = (inputValue: string | number, allowedDecimalPlaces: number): string => {
  const numericValue = parseFloat(inputValue as string);
  const decimalPart = (numericValue.toString().split('.')[1] || '').length;

  if (decimalPart > allowedDecimalPlaces) {
    return numericValue.toFixed(allowedDecimalPlaces);
  }
  return numericValue.toString();
};

export function shouldRoundPricingCell(columnType: ColumnTypeIdentifier): boolean {
  return [ColumnTypeIdentifier.SUBTOTAL].includes(columnType);
}

export function isNumericColumn(columnType: ColumnTypeIdentifier): boolean {
  return [ColumnTypeIdentifier.SUBTOTAL, ColumnTypeIdentifier.PRICE, ColumnTypeIdentifier.MULTIPLIER].includes(columnType);
}
