import React, { useState } from 'react';
import { GridApiPro, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { roundDecimalValue } from '../helper';
import { Tooltip } from '@mui/material';
import { RowTypesIdentifier } from 'components/editor/grid/reduxStore/table.types';

type InputWithTooltipsProps = Partial<GridRenderEditCellParams> & {
  blockId: string;
  numberOfDecimalPlaces?: number;
  tooltipTitle?: string | null;
  onInputChange?: (value: string) => void;
  onInputBlur?: (roundedValue: string) => void;
  onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>, apiRef: GridApiPro, localValue: string) => void;
  children?: React.ReactNode;
};

export const InputWithTooltips: React.FC<InputWithTooltipsProps> = ({
  params,
  blockId,
  numberOfDecimalPlaces,
  tooltipTitle = null,
  onInputChange,
  onInputBlur,
  onPressEnter,
  children,
}) => {
  const { id, value, field, colDef, row } = params;
  const [localValue, setLocalValue] = useState(value);
  const apiRef = useGridApiContext();
  const [formattedValue, setFormattedValue] = useState({ value: localValue, blockId: blockId });
  const isNumericInput = numberOfDecimalPlaces !== undefined && !isNaN(numberOfDecimalPlaces);

  const handleOnChange = async (value: string) => {
    if (!blockId) return;

    if (isNumericInput) {
      value = value.replace(/e/gi, '');
      const roundedValue = roundDecimalValue(value, numberOfDecimalPlaces);
      setFormattedValue({ value: roundedValue, blockId: blockId as string });
    } else {
      setFormattedValue({ value: value, blockId: blockId as string });
    }

    setLocalValue(value);
    apiRef.current.setEditCellValue({
      id,
      field,
      value,
    });

    onInputChange?.(value);
  };

  const handleBlur = async () => {
    let roundedValue = formattedValue.value;
    if (isNumericInput && !Number(roundedValue)) {
      roundedValue = 0;
    }

    setLocalValue(roundedValue);
    apiRef.current.setEditCellValue({
      id,
      field,
      value: roundedValue,
    });
    onInputBlur?.(roundedValue);
  };

  const handlePressEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      await handleBlur();
    } else if (event.key === 'e' && numberOfDecimalPlaces) {
      event.stopPropagation();
      await handleBlur();
    }

    onPressEnter?.(event, apiRef.current, localValue);
  };

  const inputClassName = isNumericInput ? 'py-number-input-no-arrow' : 'py-custom-input';

  return (
    <Tooltip title={tooltipTitle} placement="bottom">
      <span className="py-custom-cell-wrapper">
        <input
          className={inputClassName}
          type={`${colDef.type === 'number' && row.rowType !== RowTypesIdentifier.HEADER ? 'number' : 'string'}`}
          onChange={(e) => handleOnChange(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={(e) => handlePressEnter(e)}
          data-testid-row-type={row.rowType}
          data-testid-column-type={colDef.columnType}
          data-testid="input-with-tooltips"
          value={localValue || ''}
          autoFocus
        />
        {children}
      </span>
    </Tooltip>
  );
};

export default InputWithTooltips;
