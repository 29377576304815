import { useLocalStorage } from 'pages/pipeline/utils/useLocalStorage';

interface Viewer {
  id: string;
  email: string;
}

export const useViewerInfo = () => {
  const [viewer, setViewer] = useLocalStorage('viewer', { id: '', email: '' });

  const setEmailFromInput = (email: string) => {
    setViewer((identifiedViewer: Viewer) => ({ ...identifiedViewer, email: email }));
  };

  const setUnidentifiedViewer = (id: string, email: string) => {
    setViewer({ id, email: email });
  };

  const clearInputEmail = () => {
    setViewer((identifiedViewer: Viewer) => ({ ...identifiedViewer, email: '' }));
  };

  return { viewer, setEmailFromInput, clearInputEmail, setUnidentifiedViewer };
};
