import { activitiesClient } from '../api';
import { ActivitiesRepository, ActivitiesResponse, ViewedSectionsResponse } from './ActivitiesRepository';

const getActivitiesByDocumentId = async (documentId?: string): Promise<ActivitiesResponse> => {
  if (!documentId) {
    return Promise.resolve({ activities: [] } as ActivitiesResponse);
  }
  const response = await activitiesClient.get<ActivitiesResponse>(`/document/${documentId}`);
  return response;
};

const getViewedSections = async (documentId?: string): Promise<ViewedSectionsResponse> => {
  if (!documentId) {
    return Promise.resolve([]);
  }

  return [
    { id: 1, name: 'Pricing', viewedTimeInSeconds: 600 },
    { id: 2, name: 'Agreement', viewedTimeInSeconds: 480 },
    { id: 3, name: 'Service and Offerings', viewedTimeInSeconds: 360 },
    { id: 4, name: 'About Us', viewedTimeInSeconds: 180 },
    { id: 5, name: 'Timeline', viewedTimeInSeconds: 90 },
  ];
};

export const ApiActivitiesRepository: ActivitiesRepository = {
  getActivities: getActivitiesByDocumentId,
  getViewedSections: getViewedSections,
};
