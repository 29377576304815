import { useCallback } from 'react';
import { ColumnTypeIdentifier, TableColumnType, TableRowType } from '../../../grid/reduxStore/table.types';
import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import PreviewEditableCellWrapper from './PreviewEditableCellWrapper';
import InputWithTooltips from '../Table/components/InputWithTooltips';
import { shouldRoundPricingCell } from '../Table/helper';

export function useApplyCustomRulesToColumnsInPreview(blockId: string, pricingDecimalPlaces: number) {
  const applyCustomRulesToColumnsInPreview = useCallback(
    (columns: TableColumnType[], updateRows: (updatedRow: TableRowType) => void, isDocumentLocked: boolean): TableColumnType[] => {
      let updatedColumns: TableColumnType[] = [...columns];
      updatedColumns = setPreviewCustomInputToDefaultColumn(updatedColumns, updateRows, isDocumentLocked);

      return updatedColumns;
    },
    []
  );

  const setPreviewCustomInputToDefaultColumn = (
    columns: TableColumnType[],
    updateRows: (updatedRow: TableRowType) => void,
    isDocumentLocked: boolean
  ): TableColumnType[] => {
    return columns.map((column) => ({
      ...column,
      renderEditCell: (params: GridCellParams) => {
        if (shouldRoundPricingCell(column.columnType)) {
          return <InputWithTooltips params={params} blockId={blockId || ''} pricingDecimalPlaces={pricingDecimalPlaces} />;
        } else {
          return <InputWithTooltips params={params} blockId={blockId || ''} />;
        }
      },
      renderCell: (params: GridRenderCellParams) => {
        const columnType = column.columnType;

        if (shouldRoundPricingCell(columnType)) {
          return (
            <PreviewEditableCellWrapper
              updateRows={updateRows}
              params={params}
              isDocumentLocked={isDocumentLocked}
              columnType={params.colDef['columnType'] as ColumnTypeIdentifier}
              pricingDecimalPlaces={pricingDecimalPlaces}
            />
          );
        } else {
          return (
            <PreviewEditableCellWrapper
              updateRows={updateRows}
              params={params}
              isDocumentLocked={isDocumentLocked}
              columnType={params.colDef['columnType'] as ColumnTypeIdentifier}
            />
          );
        }
      },
    }));
  };

  return { applyCustomRulesToColumnsInPreview };
}
