import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  IconButton,
  Typography,
  useTheme,
  OutlinedInput,
  InputLabel,
  FormControl,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { isValidEmail } from 'utils/isValidEmail';
import CloseX from '../icon/icons/IcoCloseX';
import { useTranslation } from 'react-i18next';
import { useViewerInfo } from './useViewerInfo';

interface IdentifyToViewModalProps {
  senderName: string;
}

export const IdentifyToViewModal = ({ senderName }: IdentifyToViewModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const [inputEmailError, setInputEmailError] = useState(false);
  const { viewer, setEmailFromInput, clearInputEmail, setUnidentifiedViewer } = useViewerInfo();

  useEffect(() => {
    if (!viewer.email || !viewer.id) {
      setModalOpen(true);
    }
  }, [viewer]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputEmail = event.target.value;
    setEmailFromInput(inputEmail);
    setInputEmailError(inputEmail.length > 0 && !isValidEmail(inputEmail));
  };

  const handleViewDocumentButtonClick = () => {
    const unidentifiedViewer = { id: uuidv4(), email: viewer.email };
    setUnidentifiedViewer(unidentifiedViewer.id, unidentifiedViewer.email);
    setModalOpen(false);
  };

  const handleClearInputButtonClick = () => {
    clearInputEmail();
    setInputEmailError(false);
  };

  return (
    <>
      <Dialog data-testid="identify-to-view-modal" open={modalOpen} fullWidth>
        <DialogTitle>
          <Typography variant={'h6'} color={theme.palette.text.primary}>
            {senderName
              ? t('document.preview.identify_to_view.title_with_sender_name', { senderName })
              : t('document.preview.identify_to_view.title_without_sender_name')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ paddingTop: theme.spacing(1) }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="email">{t('document.preview.identify_to_view.email_placeholder')}</InputLabel>
              <OutlinedInput
                data-testid="identify-to-view-modal-textfield"
                id="email"
                name="email"
                type="email"
                label={t('document.preview.identify_to_view.email_placeholder')}
                value={viewer.email}
                onChange={handleEmailChange}
                error={inputEmailError}
                inputProps={{
                  'aria-label': t('document.preview.identify_to_view.email_placeholder'),
                }}
                fullWidth
                sx={{
                  borderRadius: theme.spacing(1),
                }}
                endAdornment={
                  viewer.email && (
                    <InputAdornment position="end">
                      <IconButton data-testid="identify-to-view-modal-clear-input" onClick={handleClearInputButtonClick}>
                        <CloseX />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
              <Typography color="error" variant="caption" style={{ height: theme.spacing(2) }}>
                {inputEmailError ? t('document.preview.identify_to_view.error_message') : ''}
              </Typography>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: theme.spacing(2), paddingBottom: theme.spacing(3) }}>
          <Button
            data-testid="identify-to-view-modal-button"
            variant="contained"
            disabled={inputEmailError || viewer.email.length === 0}
            onClick={handleViewDocumentButtonClick}
          >
            {t('document.preview.identify_to_view.button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
