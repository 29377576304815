import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const getIntegersAndDecimals = (input: string) => input.split('.');
export function getCommaSeparatedNumber(inputValue: string) {
  const [integerPart, decimalPart] = getIntegersAndDecimals(inputValue);
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
}
export const useFieldValidator = (initialValue: number) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [value, setValue] = useState<number>(initialValue);
  const [errorValue, setErrorValue] = useState<string | null>(null);

  const PRICE_MIN_VALUE = -9999999999.89999;
  const PRICE_MAX_VALUE = 9999999999.89999;
  const PRICE_DECIMAL_PLACES = 5;
  const PARSER_FRACTION_LIMIT = 6;
  const PARSER_INTEGERS_LIMIT = 11;
  const PARSER_NEGATIVE_INTEGERS_LIMIT = 12;

  const isFractionPassedLimit = (fraction: string) => fraction.length > PRICE_DECIMAL_PLACES;

  const getErrorMessage = (numericValue: number, fraction: string) => {
    if (numericValue > PRICE_MAX_VALUE) {
      return t('editor.error_messages.over_max_value', { maxValue: PRICE_MAX_VALUE });
    } else if (numericValue < PRICE_MIN_VALUE) {
      return t('editor.error_messages.under_min_value', { minValue: PRICE_MIN_VALUE });
    } else if (isFractionPassedLimit(fraction) && !isNaN(+fraction)) {
      return t('editor.error_messages.max_decimals_number', { decimalValue: PRICE_DECIMAL_PLACES });
    }
    return t('editor.error_messages.invalid_number');
  };

  const handleValueUpdate = useCallback(
    (numericValue: number, testValue: string) => {
      const fraction = getIntegersAndDecimals(testValue)[1] || '';

      if (/^-?\d{0,10}(\.\d{0,5})?$/.test(testValue) && numericValue >= PRICE_MIN_VALUE && numericValue <= PRICE_MAX_VALUE) {
        setError(null);
        setErrorValue(null);
      } else {
        setError(getErrorMessage(numericValue, fraction));
        setErrorValue(testValue);
      }
      setValue(numericValue);
    },
    [t]
  );

  const parseValue = useCallback(
    (inputValue: string | undefined): number => {
      let cleanedValue = inputValue?.replace(/\$\s?|(,*)/g, '') || '';
      const isNegative = cleanedValue.startsWith('-');
      const integerLimit = isNegative ? PARSER_NEGATIVE_INTEGERS_LIMIT : PARSER_INTEGERS_LIMIT;
      const [integerPart, decimalPart] = getIntegersAndDecimals(cleanedValue);

      if (!inputValue || isNaN(+cleanedValue)) {
        setErrorValue(cleanedValue);
      } else if (decimalPart?.length >= PARSER_FRACTION_LIMIT) {
        cleanedValue = `${integerPart}.${decimalPart.slice(0, PARSER_FRACTION_LIMIT)}`;
        setErrorValue(cleanedValue);
      } else if (integerPart?.length >= integerLimit) {
        cleanedValue = decimalPart ? `${integerPart.slice(0, integerLimit)}.${decimalPart}` : integerPart.slice(0, integerLimit);
        setErrorValue(cleanedValue);
      } else {
        setErrorValue(null);
      }

      const numericValue = +cleanedValue;
      handleValueUpdate(numericValue, cleanedValue);
      return numericValue;
    },
    [handleValueUpdate]
  );

  const formatter = useCallback(
    (inputValue: number | undefined): string => {
      if (inputValue === undefined || isNaN(inputValue)) {
        return '';
      }
      const formattedValue = getCommaSeparatedNumber(inputValue.toString());

      return errorValue || formattedValue;
    },
    [errorValue]
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return { error, value, formatter, parseValue };
};
