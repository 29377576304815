import { Components } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    extended: true;
    contained: true;
  }
}

export const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    root: {
      fontWeight: '600',
      borderRadius: '100px',
      textTransform: 'none',
    },
    contained: {
      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 40, 58, 0.12)',
        color: 'rgba(0, 40, 58, 0.38)',
      },
    },
    containedSecondary: {
      boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
    },
    outlined: {
      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 40, 58, 0.12)',
        color: 'rgba(0, 40, 58, 0.38)',
      },
    },
  },
  variants: [
    {
      props: { variant: 'extended' },
      style: {
        width: '232px',
        height: '56px',
        textTransform: 'capitalize',
        boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)',
        '&:hover': {
          background: '#00344a',
          boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
        },
        '&:active': {
          background: '#00344a',
          boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
        },
      },
    },
  ],
};
