import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { ViewedSectionsResponse } from 'services/activities/ActivitiesRepository';

const sectionColors = ['#00B8D1', '#33C6DA', '#54CFE0', '#8ADEEA', '#B0E9F1'];

export function ViewedSectionsChartPresenter({ viewedSections }: { viewedSections: ViewedSectionsResponse }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const sortedDataByViewedTime = [...viewedSections].sort((a, b) => b.viewedTimeInSeconds - a.viewedTimeInSeconds);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="labelMedium">{t('pipeline.insights.analytics_tab_content.total_number_of_sections')}</Typography>
        <Typography variant="labelMedium" data-testid="viewed-sections-count">
          {viewedSections.length} {t('pipeline.insights.analytics_tab_content.sections')}
        </Typography>
      </Box>
      <ResponsiveContainer width="100%" height={250} data-testid="viewed-sections-chart">
        <BarChart data={sortedDataByViewedTime} layout="vertical" barGap={0} barCategoryGap={0}>
          <XAxis type="number" hide domain={[0, 'dataMax']} />
          <YAxis type="category" dataKey="name" hide />
          <Bar dataKey="viewedTimeInSeconds" radius={[0, 0, 6, 0]}>
            {viewedSections.map((_, index: number) => (
              <Cell key={`cell-${index}`} fill={sectionColors[index % sectionColors.length]} />
            ))}
            <LabelList
              dataKey="name"
              position="insideLeft"
              style={{
                fill: theme.palette.text.primary,
                fontFamily: theme.typography.fontFamily,
                fontSize: '0.9375rem',
                fontWeight: 400,
              }}
            />
            <LabelList
              dataKey="viewedTimeInSeconds"
              position="insideRight"
              style={{
                fill: theme.palette.text.secondary,
                fontFamily: theme.typography.fontFamily,
                fontSize: '0.875rem',
                fontWeight: 400,
              }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
